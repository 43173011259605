import React, { useEffect } from 'react';
//import $ from 'jquery';
import SideMenu from '../../extra/SideMenu';
import MarketBets from '../../allBets/MarketBets';
import { 
lucky09Mid,
runTimerLu09,
startCouAutlu09,
oddsClickLu09,
cancelBet_lu09,
betSend_lu09,
stakeBet_lu09,
spinRunTime,
timeOut1,
timeOut2,
timeOut3,
settle_lu09,
resultLucky09
} from './js/lucky09';
import { GlobalContext } from '../../context/AppContext';

const Lucky0To9 = () => {

const { customer } = GlobalContext();

useEffect(() => {
lucky09Mid();
runTimerLu09();
settle_lu09();
resultLucky09();
return () => {
clearInterval(startCouAutlu09);
clearTimeout(spinRunTime);
clearTimeout(timeOut1);
clearTimeout(timeOut2);
clearTimeout(timeOut3);
settle_lu09();
}
},[]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='game-body'>
<div className='game-contents'>

<div className='casino-wrap'>

<div className='casino-content'>
<div className='casino-headRow'>
<div className='col'><img src='/images/coin.png' alt='highImg' /><span>lucky 0-9</span></div>
<div className='col'><span>Market Id:</span><span id='marketid_lucky09'>0</span></div>
</div>

<div className='wheelWrap'>
<div className='countdown-div'><span id='countstart_lu09'>0</span></div>
<span className='targetWheel'></span>
<div className='wheelspin' id='wheelspin'>
<div className='wheeldiv wheeldiv_1'><span>0</span></div>
<div className='wheeldiv wheeldiv_2'><span>1</span></div>
<div className='wheeldiv wheeldiv_3'><span>2</span></div>
<div className='wheeldiv wheeldiv_4'><span>3</span></div>
<div className='wheeldiv wheeldiv_5'><span>4</span></div>
<div className='wheeldiv wheeldiv_6'><span>5</span></div>
<div className='wheeldiv wheeldiv_7'><span>6</span></div>
<div className='wheeldiv wheeldiv_8'><span>7</span></div>
<div className='wheeldiv wheeldiv_9'><span>8</span></div>
<div className='wheeldiv wheeldiv_10'><span>9</span></div>
</div>
</div>

<div className='result-casino'>
<div className='col'><span>Result -</span></div>
<div className='col'>
<div className='resultcasDiv' id='result_lu09'></div>
</div>
</div>

<div className='lucky09_odds'>

<div style={{display:'none'}} className='placeBetCasino' id='placeBetCasino_lu09'>
<div style={{padding:'0'}} colSpan='3'>
<div className='wrapbet-row'>
<div className='bet-msgWrap' id='betMsgWrapCas_lu09'>
<div className='loaderDiv'>
<span className='dot1'></span>
<span className='dot2'></span>
<span className='loadTx'>Loading...</span>
</div>
<span id='bet_msgCas_lu09'></span>
</div>

<div className='betTeamSec'>
<span>selected team : lucky no <span id='teamCas_lu09'></span></span>
</div>

<div className='bet-input-row'>
<span style={{display:'none'}} id='selBetType_lu09'>back</span>
<span style={{display:'none'}} id='sec_lu09'>match_odds</span>
<span style={{display:'none'}} id='sport_lu09'>lucky_09</span>
<span style={{display:'none'}} id='teamSid_lu09'>1</span>
<span style={{display:'none'}} id='userCurr_lu09'>{customer.length > 0 ? customer[0].currency : 'inr'}</span>
<span style={{display:'none'}} id='eventid_lu09'>0</span>

<div className='col'>
<div className='cancel'>
<button onClick={() => cancelBet_lu09()}>cancel</button>
</div>

<div>
<span>-</span>
<input type='number' id='bet_rate_lu09' placeholder='2.00' disabled />
<span>+</span>
</div>

<div>
<span>-</span>
<input type='number' id='bet_amount_lu09' placeholder='0' disabled />
<span>+</span>
</div>

<div className='place'>
<button onClick={() => betSend_lu09()} className='' id='betsPlace_lu09'>place bet</button>
</div>

</div>
</div>

<div className='colbtnBet' id='betColBet_lu09'>
<button onClick={() => stakeBet_lu09(customer.length > 0 && (customer[0].currency === 'inr' ? 100 : 100 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 100 : 100 / 100)}</button>
<button onClick={() => stakeBet_lu09(customer.length > 0 && (customer[0].currency === 'inr' ? 250 : 250 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 250 : 250 / 100)}</button>
<button onClick={() => stakeBet_lu09(customer.length > 0 && (customer[0].currency === 'inr' ? 500 : 500 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 500 : 500 / 100)}</button>
<button onClick={() => stakeBet_lu09(customer.length > 0 && (customer[0].currency === 'inr' ? 1000 : 1000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 1000 : 1000 / 100)}</button>
<button onClick={() => stakeBet_lu09(customer.length > 0 && (customer[0].currency === 'inr' ? 2000 : 2000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 2000 : 2000 / 100)}</button>
<button onClick={() => stakeBet_lu09(customer.length > 0 && (customer[0].currency === 'inr' ? 5000 : 5000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 5000 : 5000 / 100)}</button>
<button onClick={() => stakeBet_lu09(customer.length > 0 && (customer[0].currency === 'inr' ? 10000 : 10000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 10000 : 10000 / 100)}</button>
<button onClick={() => stakeBet_lu09(customer.length > 0 && (customer[0].currency === 'inr' ? 15000 : 15000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 15000 : 15000 / 100)}</button>
</div>

<div className='mobilePlace'>
<div className='cancel'>
<button onClick={() => cancelBet_lu09()}>cancel</button>
</div>

<div className='place'>
<button onClick={() => betSend_lu09()} className='' id='betsPlace2_lu09'>place bet</button>
</div> 
</div>
</div>
</div>
</div>

<div className='lucky09_odds_row'>
<div className='lockLu096'><i className='fa fa-lock'></i></div>
<div className='col'>
<button className='lucky09_oddsBtn' onClick={() => oddsClickLu09('0')}>0</button>
</div>
<div className='col'>
<button className='lucky09_oddsBtn' onClick={() => oddsClickLu09('1')}>1</button>
</div>

<div className='col'>
<button className='lucky09_oddsBtn' onClick={() => oddsClickLu09('2')}>2</button>
</div>
<div className='col'>
<button className='lucky09_oddsBtn' onClick={() => oddsClickLu09('3')}>3</button>
</div>

<div className='col'>
<button className='lucky09_oddsBtn' onClick={() => oddsClickLu09('4')}>4</button>
</div>
<div className='col'>
<button className='lucky09_oddsBtn' onClick={() => oddsClickLu09('5')}>5</button>
</div>

<div className='col'>
<button className='lucky09_oddsBtn' onClick={() => oddsClickLu09('6')}>6</button>
</div>
<div className='col'>
<button className='lucky09_oddsBtn' onClick={() => oddsClickLu09('7')}>7</button>
</div>

<div className='col'>
<button className='lucky09_oddsBtn' onClick={() => oddsClickLu09('8')}>8</button>
</div>
<div className='col'>
<button className='lucky09_oddsBtn' onClick={() => oddsClickLu09('9')}>9</button>
</div>
</div>

<MarketBets eventid='0' />
</div>
</div>
</div>

</div>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default Lucky0To9;
