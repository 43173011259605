import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const onlBankDataApi = 'https://ag.24exchangebet.in.net/server2/bank_data';

const AddBank = () => {

const { customer, GetBankData } = GlobalContext();

const closedBank = () => {
$('#userBankModal').hide();
$('.signUpDataBank input').val('');
}

const addBankUser = async() => {
var bankName = $('#bankNameUser').val();
var holderName = $('#bankHolNameUser').val();
var bankAcNo = $('#bankAcNoUser').val();
var bankIfsc = $('#bankIfscUser').val();
let depoBankMin = 0;
let depoBankMax = 0;
var user = customer.length > 0 && customer[0].customer;
var user_role = customer.length > 0 && customer[0].user_role;
var creator = customer.length > 0 && customer[0].creator;

if(bankName === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('bank name required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(holderName === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('holder name required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(bankAcNo === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('account number required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(bankIfsc === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('ifsc code required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('https://ag.24exchangebet.in.net/server2/add_bank', {
bankName : bankName,
holderName : holderName,
bankAcNo : bankAcNo,
bankIfsc : bankIfsc,
depoBankMin : depoBankMin,
depoBankMax : depoBankMax,
customer : user,
user_role : user_role,
creator : creator
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('bank add successfully');
setTimeout(() => {
$('#msgAlert').hide();
$('#userBankModal').hide();
$('.signUpDataBank input').val('');
GetBankData(onlBankDataApi);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);    
}
} catch (error) {
console.log('frontend add_bank_data error : ' + error);
}
}
}

return (
<>
<div className='signUpModal' id='userBankModal'>
<div className='signUpCon'>
<div className='signUpHead'>
<span>add bank account</span>
<i className='fa fa-close' onClick={closedBank} />
</div>

<div className='signUpBody'>
<div className='signupForm' id='signupForm'>
<div className='signUpData signUpDataBank'>
<label>bank name *</label>
<input type='text' className='bankNameUser' name='bankNameUser' id='bankNameUser' placeholder='Enter bank name...' />
</div>

<div className='signUpData signUpDataBank'>
<label>bank holder name *</label>
<input type='text' className='bankHolNameUser' name='bankHolNameUser' id='bankHolNameUser' placeholder='Enter bank holder name...' />
</div>

<div className='signUpData signUpDataBank'>
<label>bank account no. *</label>
<input type='text' className='bankAcNoUser' name='bankAcNoUser' id='bankAcNoUser' placeholder='Enter bank ac number...' />
</div>

<div className='signUpData signUpDataBank'>
<label>bank ifsc code *</label>
<input type='text' className='bankIfscUser' name='bankIfscUser' id='bankIfscUser' placeholder='Enter bank ifsc code...' />
</div>


<div className='signUpConf signUpBtnD'>
<button onClick={addBankUser}>create account</button>
<button onClick={closedBank}>cancel</button>
</div>
</div>
</div>

</div>
</div>
</>
)
}

export default AddBank;
