import React, { useEffect } from 'react';
import $ from 'jquery';

const Slider = () => {

var sliderImg = [
{slide: '/images/slide1.png'},
{slide: '/images/slide2.png'},
{slide: '/images/slide3.png'},
{slide: '/images/slide4.png'},
{slide: '/images/slide5.png'}
];

let slideIndex = 0;
var startSlide;

const setLeftSlide = () => {
slideIndex = parseInt(slideIndex) - 1; 
if(slideIndex < 0){
slideIndex = sliderImg.length - 1;
}   
$('.sliderNo').hide();
$('#sliderNo_'+slideIndex).show();   
}

const setRightSlide = () => {
slideIndex = parseInt(slideIndex) + 1; 
if(slideIndex > (sliderImg.length - 1)){
slideIndex = 0;
}   
$('.sliderNo').hide();
$('#sliderNo_'+slideIndex).show();
}

startSlide = setInterval(runSlide, 5000);

function runSlide(){
slideIndex++;
if(slideIndex > (sliderImg.length - 1)){
slideIndex = 0;
}
$('.sliderNo').hide();
$('#sliderNo_'+slideIndex).show();
}

useEffect(() => {
return () => {
clearInterval(startSlide);
}
},[startSlide]);

return (
<>
<div className='slider-area'>
<div className='slider-all'>
<button className='slideBtn slideLeft' onClick={setLeftSlide}><i className='fa fa-chevron-left'></i></button>
{
sliderImg.length > 0 && sliderImg.map((data, index) => {
return (
<img className='sliderNo' key={index} src={data.slide} alt='slider' id={'sliderNo_'+index} />
) 
})
}
<button className='slideBtn slideRight' onClick={setRightSlide}><i className='fa fa-chevron-right'></i></button>
</div>
</div>
</>
)
}

export default Slider;
