import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import SideMenu from '../extra/SideMenu';
import Footer from '../extra/Footer';
import { GlobalContext } from '../context/AppContext';
import FrontPay from '../pages/FrontPay';

const TennListApi = '/server/betfair-tennis';
const MultiApi = '/server/customer-multimarket';

const Tennis = () => {

const Navigate = useNavigate();
const { tennList, GetTennList, customer, multimarket, GetMultiList, spoSett, gameSett } = GlobalContext();
const [lockSport, setLockSport] = useState([]);

const pinEvent = async(eid,gmid,mid) => {
if (customer.length > 0){
//alert(eid +' -- '+ gmid +' -- '+ mid);
try {
const sendData = await axios.post('/server/multimarkets', {customer:customer[0].customer, eid:eid, gmid:gmid, mid:mid});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('pin event successfully');
GetMultiList(`${MultiApi}`);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else if(resData === 'removed'){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('pin removed successfully');
GetMultiList(`${MultiApi}`);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);   
}
} catch (error) {
console.log('frontend tennis multiadd-api error: ' + error);
}
}
else{
$('#AcceptAgeModal').fadeIn();
}
}

const pinEventData = tennList.filter(i => multimarket.some(j => parseFloat(i.gmid) === parseFloat(j.gmid) && parseFloat(i.mid) === parseFloat(j.mid) && parseInt(i.etid) === parseInt(j.eid)));
const noPinEventData = tennList.filter(i => !multimarket.some(j => parseFloat(i.gmid) === parseFloat(j.gmid) && parseFloat(i.mid) === parseFloat(j.mid) && parseInt(i.etid) === parseInt(j.eid)));

const openMarket = (eventid, marketid) => {
Navigate('/market-2/'+eventid+'/'+marketid);
}

useEffect(() => {
GetTennList(`${TennListApi}`);
/* eslint-disable react-hooks/exhaustive-deps */
}, []);

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent || e.username === f.customer)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.customer)));
}
}
},[customer, spoSett]);

useEffect(() => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'tennis');
//console.log(checkLock);
if(checkLock.length > 0){
$('#sports_lock2').css({display: 'flex'});
}else{
$('#sports_lock2').hide();
}
}
},[lockSport]);

useEffect(() => {
if(gameSett.length > 0 && tennList.length > 0){
tennList.filter((e) => gameSett.find((o) => parseFloat(e.cid) === parseFloat(o.eventid) && o.sport === 'tennis')).forEach((k) => {
$('#GameLock_'+k.cid+k.mid).css({display: 'flex'});
});
}
},[gameSett, tennList]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='game-body'>
<div className='singleImage'>
<img src='/images/slide3.png' alt='singleImg' />
</div>

<FrontPay />

<div className='game-contents'>
<div className='highlightdIV'><img src='/images/tennis.png' alt='highImg' /> <span>tennis highlights</span></div>

<div className='sports-page'>

<div className='sports_lock' id='sports_lock2'>
<div className='sport_lockTxt'>
<img src='/images/lock.png' alt='lock' />
<span>sport is locked</span>
<span>contact your service provider</span>
</div>
</div>

<table className='sports-page-tabel'>
<thead>
<tr>
<th></th>
<th></th>
<th>1</th>
<th>2</th>
<th></th>
</tr>
</thead>
{
tennList.length > 0 && tennList.map((data, index) => {
var iplay = '';
var eventTime = '';
var timeParse = Date.parse(data.stime);
if(data.iplay === true || data.iplay === 'true'){
iplay = <span className='inplay-avail'><img src='../images/green-circle.png' alt='sportsIco' /></span>
}
else{
eventTime = <span className='event-time'>{new Date(timeParse).toLocaleString()}</span>;
}
return (
<tbody key={index}>

<tr>
<td className='GameLock' id={'GameLock_'+data.cid+data.mid}>
<span>event is locked <i className='fa fa-lock colLock'></i></span>
</td>
</tr>

<tr>
<td>
<span className='blockSpan'>
<span className='event-name' onClick={() => openMarket(data.gmid, data.mid)}>{data.ename.trim().replace('-', 'v')}</span>
{eventTime}
</span>
</td>
<td><span className='blockSpan2'>{iplay}</span></td>
{
data.section.length > 0 && data.section.map((data2, index2) => {
return (
<td className='counts-rates' key={index2}>
<span className='back-sports-page'>{data2.odds[0].otype === 'back' ? data2.odds[0].odds : data2.odds[1].odds}</span>
<span className='lay-sports-page'>{data2.odds[1].otype === 'lay' ? data2.odds[1].odds : data2.odds[0].odds}</span>
</td>
)
})   
}

{
pinEventData.length > 0 && pinEventData.filter(h => h.gmid === data.gmid).map((data2, index2) => {
return (
<td key={index2} onClick={() => pinEvent(data2.etid, data2.gmid, data2.mid)}><img className='pinImg' src='../images/pin-green.png' alt='pngIco' /></td>
)
})
}

{
noPinEventData.length > 0 && noPinEventData.filter(h => h.gmid === data.gmid).map((data3, index3) => {
return (
<td key={index3} onClick={() => pinEvent(data3.etid, data3.gmid, data3.mid)}><img className='pinImg' src='../images/pin.png' alt='pngIco' /></td>
)
})
}
</tr>
</tbody>
)
})  
}
</table>
</div>
<Footer />
</div>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default Tennis;
