import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../context/AppContext';

const FrontPay = () => {

const Navigate = useNavigate();
const { customer } = GlobalContext();

const frPayTy = (type) => {
Navigate('/online_banking', {state: {type: type}});
}

return (
<>
{
customer.length > 0 &&
<div className='frontPayDiv'>
<div className='buttonPayBx'>
<img src='/images/new.png' alt='new' />
<button onClick={() => frPayTy('deposit')}>deposit</button>
<button onClick={() => frPayTy('withdraw')}>withdraw</button>
</div>
</div>
}
</>
)
}

export default FrontPay;
