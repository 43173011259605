import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const onlUpiDataApi = 'https://ag.24exchangebet.in.net/server2/upi_data';

const AddUpi = () => {

const { customer, GetUpiData } = GlobalContext();

const closeddUpi = () => {
$('#userUpiModal').hide();
$('.signUpData input').val('');
$('.signUpData select option').prop('selected', null);
}

const addUpiUser = async() => {
var upiAcName = $('#upiAcUser').val();
var upiAcId = $('#upiAcIdUser').val();
var upiType = $('#upiTypeUser').val();
let depoUpiMin = 0;
let depoUpiMax = 0;
var user = customer.length > 0 && customer[0].customer;
var user_role = customer.length > 0 && customer[0].user_role;
var creator = customer.length > 0 && customer[0].creator;

if(upiAcName === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('UPI account name required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(upiAcId === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('UPI account id required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(upiType === 'null' || upiType === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('UPI account type required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('https://ag.24exchangebet.in.net/server2/upi_add', {
upiAcName : upiAcName,
upiAcId : upiAcId,
upiType : upiType,
depoUpiMin : depoUpiMin,
depoUpiMax : depoUpiMax,
customer : user,
user_role : user_role,
creator : creator
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('UPI created successfully');
setTimeout(() => {
$('#msgAlert').hide();
$('#userUpiModal').hide();
$('.signUpData input').val('');
$('.signUpData select option').prop('selected', null);
GetUpiData(onlUpiDataApi);
},1000);
return false;  
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
} catch (error) {
console.log('frontend add_upi_details error : ' + error);   
}
}
}

return (
<>
<div className='signUpModal' id='userUpiModal'>
<div className='signUpCon'>
<div className='signUpHead'>
<span>add upi account</span>
<i className='fa fa-close' onClick={closeddUpi} />
</div>

<div className='signUpBody'>
<div className='signupForm' id='signupForm'>
<div className='signUpData'>
<label>upi account name *</label>
<input type='text' className='upiAcUser' name='upiAcUser' id='upiAcUser' placeholder='Enter upi account name...' />
</div>

<div className='signUpData'>
<label>upi account id *</label>
<input type='text' className='upiAcIdUser' name='upiAcIdUser' id='upiAcIdUser' placeholder='Enter upi account id...' />
</div>

<div className='signUpData'>
<label>upi account type *</label>
<select id='upiTypeUser'>
<option value='null'>--please select payment type--</option>
<option value='phonepay'>phonepay</option>
<option value='gpay'>gpay</option>
<option value='paytm'>paytm</option>
<option value='bhim'>bhim upi</option>
</select>
</div>

<div className='signUpConf signUpBtnD'>
<button onClick={addUpiUser}>create account</button>
<button onClick={closeddUpi}>cancel</button>
</div>
</div>
</div>

</div>
</div>
</>
)
}

export default AddUpi;
