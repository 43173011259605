import React from 'react';
import Social from './Social';
import Notice from'./Notice';
import TopHeader from './TopHeader';
import Header from './Header';
import MainMenu from './MainMenu';

const HeadFix = () => {
return (
<>
<div className='fixHeader'>
<Social />
<Notice />
<TopHeader />
<Header />
<MainMenu />
</div>
</>
)
}

export default HeadFix;
