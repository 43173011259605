import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AutoScroll from './extra/AutoScroll';
import HomePage from './pages/HomePage';
import GameRules from './pages/GameRules';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Kyc from './pages/Kyc';
import Gaming from './pages/Gaming';
import About from './pages/About';
import Cricket from './highlights/Cricket';
import Soccer from './highlights/Soccer';
import Tennis from './highlights/Tennis';
import MultiMarket from './pages/MultiMarket';
import MainInplay from './inPlay/MainInplay';
import CasinoMain from './pages/CasinoMain';
import VirtualMain from './pages/VirtualMain';
import Stake from './pages/Stake';
import ChangePass from './pages/ChangePass';
import ActivityLog from './pages/ActivityLog';
import BetHistory from './pages/BetHistory';
import ProfitLoss from './profiLoss/ProfitLoss';
import Event from './profiLoss/Event';
import History from './profiLoss/History';
import CricketMarket from './markets/cricket/CricketMarket';
import HeadTails from './virtualCasino/headTail/HeadTails';
import SoccerMarket from './markets/soccer/SoccerMarket';
import TennisMarket from './markets/tennis/TennisMarket';
import MobileFooter from './extra/MobileFooter';
import AllSports from './pages/AllSports';
import MobileAc from './pages/MobileAc';
import SportsLock from './modals/SportsLock';
import Statement from './pages/Statement';
import MyBets from './myBetsAll/MyBets';
import EventBets from './myBetsAll/EventBets';
import DefaultPass from './modals/DefaultPass';
import AutoLogOut from './pages/AutoLogOut';
import Online from './pages/Online';
import ErrorPage from './pages/ErrorPage';
import Upcoming from './modals/Upcoming';
import DemoLogin from './modals/DemoLogin';
import T20 from './casino/t20/T20';
import T20OneDay from './casino/t20one_day/T20OneDay';
import Dt20 from './casino/dt20/Dt20';
import Dt202 from './casino/dt202/Dt202';
import T20Test from './casino/t20test/T20Test';
import Dt1Day from './casino/dt1day/Dt1Day';
import Lucky7A from './casino/lucky7A/Lucky7A';
import Lucky7B from './casino/lucky7B/Lucky7B';
import AndarBahar from './casino/andarBahar/AndarBahar';
import Lucky0To9 from './virtualCasino/lucky0to9/Lucky0To9';
import HeadFix from './extra/HeadFix';
import SignUp from './modals/SignUp';
import Banking from '../src/onlineBanking/Banking';
import MyProfile from './pages/MyProfile';
import ForgotPass from './modals/forgotPass/ForgotPass';
import NewPass from './modals/forgotPass/NewPass';

function App() {
return (
<BrowserRouter>
<AutoScroll />
<AutoLogOut />
<Online />
<DefaultPass />
<Upcoming />
<DemoLogin />
<SportsLock />
<HeadFix />
<SignUp />
<ForgotPass />
<NewPass />
<Routes>
<Route path='/' element={<HomePage />} />
<Route path='/games-rules' element={<GameRules />} />
<Route path='/privacy' element={<Privacy />} />
<Route path='/terms' element={<Terms />} />
<Route path='/kyc' element={<Kyc />} />
<Route path='/responsible-gaming' element={<Gaming />} />
<Route path='/about' element={<About />} />
<Route path='/cricket' element={<Cricket />} />
<Route path='/soccer' element={<Soccer />} />
<Route path='/tennis' element={<Tennis />} />
<Route path='/multimarket' element={<MultiMarket />} />
<Route path='/in-play' element={<MainInplay />} />
<Route path='/all-casino' element={<CasinoMain />} />
<Route path='/all-virtual-casino' element={<VirtualMain />} />
<Route path='/stake-setting' element={<Stake />} />
<Route path='/change-password' element={<ChangePass />} />
<Route path='/activity-log' element={<ActivityLog />} />
<Route path='/bet-history' element={<BetHistory />} />
<Route path='/market-4/:eventid/:marketid' element={<CricketMarket />} />
<Route path='/market-1/:eventid/:marketid' element={<SoccerMarket />} />
<Route path='/market-2/:eventid/:marketid' element={<TennisMarket />} />
<Route path='/head-tails' element={<HeadTails />} />
<Route path='/profit-loss' element={<ProfitLoss />} />
<Route path='/profit-loss/event' element={<Event />} />
<Route path='/profit-loss/event/bet_history' element={<History />} />
<Route path='/all-sports' element={<AllSports />} />
<Route path='/mobile-account' element={<MobileAc />} />
<Route path='/statement' element={<Statement />} />
<Route path='/my_bets' element={<MyBets />} />
<Route path='/my_bets/event' element={<EventBets />} />
<Route path='/all-casino/t20' element={<T20 />} />
<Route path='/all-casino/t20_oneday' element={<T20OneDay />} />
<Route path='/all-casino/dt20' element={<Dt20 />} />
<Route path='/all-casino/dt202' element={<Dt202 />} />
<Route path='/all-casino/t20_test' element={<T20Test />} />
<Route path='/all-casino/dt_1day' element={<Dt1Day />} />
<Route path='/all-casino/lucky_7a' element={<Lucky7A />} />
<Route path='/all-casino/lucky_7b' element={<Lucky7B />} />
<Route path='/all-casino/ab' element={<AndarBahar />} />
<Route path='/lucky09' element={<Lucky0To9 />} />
<Route path='/online_banking' element={<Banking />} />
<Route path='/my_profile' element={<MyProfile />} />
<Route path='*' element={<ErrorPage />} />
</Routes>
<MobileFooter />
</BrowserRouter>
);
}

export default App;

