const Reducer = (state, action) => {
switch (action.type) {
case 'CRICKET_LIST_LOADING':
return{
...state,
isLoadCric: true,
isCricErr: false,
}
case 'CRICKET_LIST_SET':
return{
...state,
isLoadCric: false,
isCricErr: false,
cicList: action.payload,
}
case 'CRICKET_LIST_ERROR':
return{
...state,
isLoadCric: false,
isCricErr: true,
}
case 'SOCCER_LIST_LOADING':
return{
...state,
isLoadSoc: true,
isSocErr: false,
}
case 'SOCCER_LIST_SET':
return{
...state,
isLoadSoc: false,
isSocErr: false,
soccList: action.payload,
}
case 'SOCCER_LIST_ERROR':
return{
...state,
isLoadSoc: false,
isSocErr: true,
}
case 'TENNIS_LIST_LOADING':
return{
...state,
isLoadTenn: true,
isTennErr: false,
}
case 'TENNIS_LIST_SET':
return{
...state,
isLoadTenn: false,
isTennErr: false,
tennList: action.payload,
}
case 'TENNIS_LIST_ERROR':
return{
...state,
isLoadTenn: false,
isTennErr: true,
}
case 'USER_LIST_LOADING':
return{
...state,
isUserLoad: true,
isUserErr: false,
}
case 'USER_LIST_SET':
return{
...state,
isUserLoad: false,
isUserErr: false,
customer: action.payload,
}
case 'USER_LIST_ERROR':
return{
...state,
isUserLoad: false,
isUserErr: true,
}
case 'MULTI_LIST_LOADING':
return{
...state,
isMultiLoad: true,
isMultiErr: false,
}
case 'MULTI_LIST_SET':
return{
...state,
isMultiLoad: false,
isMultiErr: false,
multimarket: action.payload,
}
case 'MULTI_LIST_ERROR':
return{
...state,
isMultiLoad: false,
isMultiErr: true,
}
case 'ACTIVITY_LOG_LOADING':
return{
...state,
isLoadActvity: true,
isErrActivity: false,
}   
case 'ACTIVITY_LOG_SET':
return{
...state,
isLoadActvity: false,
isErrActivity: false,
activityLog: action.payload,
}   
case 'ACTIVITY_LOG_ERROR':
return{
...state,
isLoadActvity: false,
isErrActivity: true,
} 
case 'USER_EXPO_LOADING':
return{
...state,
isExpoLoad: true,
isExpoErr: false,
} 
case 'USER_EXPO_SET':
return{
...state,
isExpoLoad: false,
isExpoErr: false,
exposure: action.payload,
} 
case 'USER_EXPO_LOG_ERROR':
return{
...state,
isExpoLoad: false,
isExpoErr: true,
} 
case 'PROFIT_LOSS_LOADING':
return{
...state,
isProfitLoad: true,
isProfitErr: false,
} 
case 'PROFIT_LOSS_SET':
return{
...state,
isProfitLoad: false,
isProfitErr: false,
profitLossUser: action.payload
}  
case 'PROFIT_LOSS_ERROR':
return{
...state,
isProfitLoad: false,
isProfitErr: true,
} 
case 'CRICKET_MARKET_LOADING':
return{
...state,
isCricMarLoad: true,
isCricMarErr: false,
} 
case 'CRICKET_MARKET_SET':
return{
...state,
isCricMarLoad: false,
isCricMarErr: false,
cricMarket: action.payload,
} 
case 'CRICKET_MARKET_ERROR':
return{
...state,
isCricMarLoad: false,
isCricMarErr: true,
} 
case 'SOCCER_MARKET_LOADING':
return{
...state,
isSoccMarLoad: true,
isSoccMarErr: false,
} 
case 'SOCCER_MARKET_SET':
return{
...state,
isSoccMarLoad: false,
isSoccMarErr: false,
soccMarket: action.payload,
} 
case 'SOCCER_MARKET_ERROR':
return{
...state,
isSoccMarLoad: false,
isSoccMarErr: true,
} 
case 'CRICKET_COMPETITION_LOADING':
return{
...state,
isCompetitionLoad: true,
isCompetitionErr: false
} 
case 'CRICKET_COMPETITION_SET':
return{
...state,
isCompetitionLoad: false,
isCompetitionErr: false,
CompetitionList: action.payload
} 
case 'CRICKET_COMPETITION_ERROR':
return{
...state,
isCompetitionLoad: false,
isCompetitionErr: true
} 
case 'TENNIS_MARKET_LOADING':
return{
...state,
isTennMarLoad: true,
isTennMarErr: false,
} 
case 'TENNIS_MARKET_SET':
return{
...state,
isTennMarLoad: false,
isTennMarErr: false,
tennMarket: action.payload,
} 
case 'TENNIS_MARKET_ERROR':
return{
...state,
isTennMarLoad: false,
isTennMarErr: true,
} 
case 'NOTICE_LOADING':
return{
...state,
noticeLoad: true,
noticeErr: false
} 
case 'NOTICE_SET':
return{
...state,
noticeLoad: false,
noticeErr: false,
noticeAll: action.payload
} 
case 'NOTICE_ERROR':
return{
...state,
noticeLoad: false,
noticeErr: true
} 
case 'SPORT_SETTING_LOADING':
return{
...state,
loadSpoSett: true,
errSpoSett: false
} 
case 'SPORT_SETTING_SET':
return{
...state,
loadSpoSett: false,
errSpoSett: false,
spoSett: action.payload
} 
case 'SPORT_SETTING_ERROR':
return{
...state,
loadSpoSett: false,
errSpoSett: true
} 

case 'GAME_SETTING_LOADING':
return{
...state,
loadGameSett: true,
errGameSett: false
} 
case 'GAME_SETTING_SET':
return{
...state,
loadGameSett: false,
errGameSett: false,
gameSett: action.payload
} 
case 'GAME_SETTING_ERROR':
return{
...state,
loadGameSett: false,
errGameSett: true
} 

case 'CRICKET_COMP_LOADING':
return{
...state,
loadCricComp: true,
errCricComp: false
} 

case 'CRICKET_COMP_SET':
return{
...state,
loadCricComp: false,
errCricComp: false,
cricCompAll: action.payload
} 

case 'CRICKET_COMP_ERROR':
return{
...state,
loadCricComp: false,
errCricComp: true
}

case 'BET_SETTING_LOADING':
return{
...state,
loadBetSett: true,
errBetSett: false
}

case 'BET_SETTING_SET':
return{
...state,
loadBetSett: false,
errBetSett: false,
betSett: action.payload
}

case 'BET_SETTING_ERROR':
return{
...state,
loadBetSett: false,
errBetSett: true
}

case 'ALL_BETS_LOADING':
return{
...state,
loadAllBet: true,
errAllBet: false
}

case 'ALL_BETS_SET':
return{
...state,
loadAllBet: false,
errAllBet: false,
allBetsData: action.payload
}

case 'ALL_BETS_ERROR':
return{
...state,
loadAllBet: false,
errAllBet: true
}

case 'UPI_ADM_LOADING':
return{
...state,
loadUpiAdm: true,
errUpiAdm: false
}

case 'UPI_ADM_SET':
return{
...state,
loadUpiAdm: false,
errUpiAdm: false,
upiAdm: action.payload
}

case 'UPI_ADM_ERROR':
return{
...state,
loadUpiAdm: false,
errUpiAdm: true
}

case 'BANK_ADM_LOADING':
return{
...state,
loadBankAdm: true,
errBankAdm: false
}

case 'BANK_ADM_SET':
return{
...state,
loadBankAdm: false,
errBankAdm: false,
bankAdm: action.payload
}

case 'BANK_ADM_ERROR':
return{
...state,
loadBankAdm: false,
errBankAdm: true
}

case 'TRANSATION_ONLINE_LOADING':
return{
...state,
loadTran: true,
errTran: false
}

case 'TRANSATION_ONLINE_SET':
return{
...state,
loadTran: false,
errTran: false,
tranData: action.payload
}

case 'TRANSATION_ONLINE_ERROR':
return{
...state,
loadTran: false,
errTran: true
}

case 'UPIDATA_ONLINE_LOADING':
return{
...state,
loadUpi: true,
errUpi: false
}

case 'UPIDATA_ONLINE_SET':
return{
...state,
loadUpi: false,
errUpi: false,
upiData: action.payload
}

case 'UPIDATA_ONLINE_ERROR':
return{
...state,
loadUpi: false,
errUpi: true
}

case 'BANKDATA_ONLINE_LOADING':
return{
...state,
loadBank: true,
errBank: false
}

case 'BANKDATA_ONLINE_SET':
return{
...state,
loadBank: false,
errBank: false,
bankData: action.payload
}

case 'BANKDATA_ONLINE_ERROR':
return{
...state,
loadBank: false,
errBank: true
}

default: return state;
}
}

export default Reducer;