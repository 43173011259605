import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const onlUpiDataApi = 'https://ag.24exchangebet.in.net/server2/upi_data';

const EditUpi = ({editUpiData, setEditUpiData}) => {

const { GetUpiData } = GlobalContext();

const closedUpdUpi = () => {
$('#userUpiUpdModal').hide(); 
setEditUpiData([]);  
}

const updateUserUpi = async(type) => {
var id = editUpiData.length > 0 ? editUpiData[0].id : null;
var upiAcName = $('#upiAcUserUpd').val().toLowerCase();
var upiAcId = $('#upiAcIdUserUpd').val().toLowerCase();
var upiType = $('#upiTypeUserUpd').val().toLowerCase();
//alert(id +' -- '+ upiAcName +' -- '+ upiAcId +' -- '+ upiType);

if(upiAcName === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('upi name required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(upiAcId === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('upi id required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(upiType === 'null'){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('upi type required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server/edit_upi', {
type: type,
id: id,
upiAcName: upiAcName,
upiAcId: upiAcId,
upiType: upiType
});

const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('upi updated successfully');
setTimeout(() => {
$('#msgAlert').hide();
$('#userUpiUpdModal').hide(); 
GetUpiData(onlUpiDataApi);
setEditUpiData([]); 
},1000);
return false; 
}
else if(resData === 'success2'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('upi removed successfully');
setTimeout(() => {
$('#msgAlert').hide();
$('#userUpiUpdModal').hide(); 
GetUpiData(onlUpiDataApi);
setEditUpiData([]); 
},1000);
return false; 
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);   
}
} catch (error) {
console.log('frontend edit-upi error : ' + error);
}
}
}

return (
<>
<div className='signUpModal' id='userUpiUpdModal'>
<div className='signUpCon'>
<div className='signUpHead'>
<span>update/remove upi</span>
<i className='fa fa-close' onClick={closedUpdUpi} />
</div>

<div className='signUpBody'>
{
editUpiData.length > 0 &&
<div className='signupForm' id='signupForm'>
<div className='signUpData'>
<label>upi account name *</label>
<input type='text' defaultValue={editUpiData[0].upiAcName} name='upiAcUserUpd' id='upiAcUserUpd' placeholder='Enter upi account name...' />
</div>

<div className='signUpData'>
<label>upi account id *</label>
<input type='text' defaultValue={editUpiData[0].upiAcId} name='upiAcIdUserUpd' id='upiAcIdUserUpd' placeholder='Enter upi account id...' />
</div>

<div className='signUpData'>
<label>upi account type *</label>
<select id='upiTypeUserUpd'>
<option defaultValue={editUpiData[0].upiType}>{editUpiData[0].upiType}</option>
<option value='null'>--please select payment type--</option>
<option value='phonepay'>phonepay</option>
<option value='gpay'>gpay</option>
<option value='paytm'>paytm</option>
<option value='bhim'>bhim upi</option>
</select>
</div>

<div className='signUpConf signUpBtnD'>
<button onClick={() => updateUserUpi('update')}>update upi</button>
<button onClick={() => updateUserUpi('reject')}>remove upi</button>
</div>
</div>
}
</div>

</div>
</div>
</>
)
}

export default EditUpi;
