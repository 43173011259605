import React, { useEffect } from 'react';
import $ from 'jquery';

const Social = () => {

useEffect(() => {
function themeTime(){
var ThemeDateTime = new Date().toLocaleString();
$('#currTime_themeSpan').text(ThemeDateTime);
}

setInterval(themeTime, 1000);
},[]);

return (
<>
<div className='socialHeadWrap'>
<div className='socialHead'>
<div className='col'>
<div className='socialContent'>
<i className='fa fa-instagram' />
<span>instagram</span>
</div>
<div className='socialContent'>
<i className='fa fa-facebook' />
<span>facebook</span>
</div>
<div className='socialContent'>
<i className='fa fa-twitter' />
<span>twitter</span>
</div>
<div className='socialContent'>
<i className='fa fa-telegram' />
<span>telegram</span>
</div>
<div className='socialContent'>
<i className='fa fa-whatsapp' />
<span>whatsapp</span>
</div>
</div>
<div className='col'>
<div className='currTime_theme'><span id='currTime_themeSpan'>{new Date().toLocaleString()}</span></div>
</div>
</div>
</div>
</>
)
}

export default Social;
