import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const SignUp = () => {

const signUpNew = async() => {
var senderMail =  $('#signup_gmail').val();
var signup_username = $('#signup_username').val();
var signup_name = $('#signup_name').val();
var signup_mobile = $('#signup_mobile').val();
var signup_pass = $('#signup_pass').val();
var signup_confpass = $('#signup_confpass').val();

if(signup_username.length < 6){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('username 6 length alpha, char and number required');
setTimeout(() => {
$('#msgAlert').hide();
},2000);
return false;
}
if(signup_name === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('your name is required');
setTimeout(() => {
$('#msgAlert').hide();
},2000);
return false;
}
if(signup_mobile.length < 10 || signup_mobile.length > 10){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('10 digit mobile number required');
setTimeout(() => {
$('#msgAlert').hide();
},2000);
return false;
}
if(!senderMail.match("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$")){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('mail id wrong or required');
setTimeout(() => {
$('#msgAlert').hide();
},2000);
return false;
}
if(signup_pass.length < 6){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('password min. 6 length required');
setTimeout(() => {
$('#msgAlert').hide();
},2000);
return false;
}
if(signup_pass !== signup_confpass){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('confirm password not macth');
setTimeout(() => {
$('#msgAlert').hide();
},2000);
return false;
}
else{
try {
$('#OtpLoadWrap').css({display: 'grid'});
const sendData = await axios.post('/server/signUp', {
senderMail: senderMail,
signup_username: signup_username
});
const resData = await sendData.data;
if(resData === 'success'){
$('#signupForm').hide();
$('#verifyOtpDiv').css({display: 'grid'});
$('#OtpLoadWrap').hide();
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
$('#OtpLoadWrap').hide();
},2000);
}
} catch (error) {
console.log('backend signup_user error : ' + error);
}
}
}

const otpChange = (val) => {
const next = val.nextElementSibling;
const prev = val.previousElementSibling;
if (val.value !== "") {
if (next) {
next.focus();
}
}
else if(val.value === ''){
if(prev){
prev.focus();
}   
}
}

const verifyOtpConf = async() => {
var otp_1 = $('#otp_1').val();
var otp_2 = $('#otp_2').val();
var otp_3 = $('#otp_3').val();
var otp_4 = $('#otp_4').val();
var otp_5 = $('#otp_5').val();
var otp_6 = $('#otp_6').val();
var otpConf = otp_1 + otp_2 + otp_3 + otp_4 + otp_5 + otp_6;
var senderMail =  $('#signup_gmail').val();
var signup_username = $('#signup_username').val();
var signup_name = $('#signup_name').val();
var signup_curre = $('#signup_curre').val();
var signup_mobile = $('#signup_mobile').val();
var signup_confpass = $('#signup_confpass').val();
var currency = '';

if(signup_curre === 'null'){
currency = 'inr';  
}
else{
currency = signup_curre;
}

if(otpConf.length < 6){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('entered wrong otp');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server/verify_otp', {
otpConf: otpConf,
senderMail : senderMail,
signup_username: signup_username,
signup_name: signup_name,
signup_curre: currency,
signup_mobile: signup_mobile,
signup_confpass: signup_confpass,
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('account created successfully');
setTimeout(() => {
$('#msgAlert').hide();
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000); 
}
} catch (error) {
console.log('backend verify_otp error : ' + error);
}
}
}

const verifyOtpCancel = () => {
$('#verifyOtpDiv').hide();
$('#signupForm').show();
$('.otpBox').val('');
}

const closesignUp = () => {
$('#signUpModal').hide();
$('#verifyOtpDiv').hide();
$('.otpBox').val('');  
$('.signVal').val(''); 
$('#signup_curre option').prop('selected', null); 
}

const resendOtp = () => {
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('otp sent successfully');
setTimeout(() => {
$('#msgAlert').hide();
signUpNew();
},1000);
}

const changeMobileC = (val) => {
if(val <= 0){
$('#signup_mobile').val(0);
}
}

return (
<>
<div className='signUpModal' id='signUpModal'>
<div className='signUpCon'>
<div className='signUpHead'>
<span>create account</span>
<i className='fa fa-close' onClick={closesignUp} />
</div>

<div className='signUpBody'>

<div className='OtpLoadWrap' id='OtpLoadWrap'>
<div className='groupDiv'>
<div className='OtpLoad'>
<span></span>
</div>
<div className='OtpLoadTxt'>
<span>loading verification...</span>
</div>
</div>
</div>

<div className='signupForm' id='signupForm'>
<div className='signUpData'>
<label>username *</label>
<input type='text' className='signVal' name='signup_username' id='signup_username' placeholder='Enter Username...' />
</div>

<div className='signUpData'>
<label>name *</label>
<input type='text' className='signVal' name='signup_name' id='signup_name' placeholder='Enter full name...' />
</div>

<div className='signUpData'>
<label>currency type</label>
<select id='signup_curre'>
<option value='null'>---select currency type---</option>
<option value='inr'>INR</option>
<option value='hkd'>HKD</option>
</select>
</div>

<div className='signUpData'>
<label>mobile no. *</label>
<input type='number' onChange={(e) => changeMobileC(e.target.value)} className='signVal' name='signup_mobile' id='signup_mobile' placeholder='Enter mobile number...' />
</div>

<div className='signUpData'>
<label>mail id. *</label>
<input type='text' className='signVal' name='signup_gmail' id='signup_gmail' placeholder='Enter mail account...' />
</div>

<div className='signUpData'>
<label>password. *</label>
<input type='text' className='signVal' name='signup_pass' id='signup_pass' placeholder='Enter password...' />
</div>

<div className='signUpData'>
<label>conf. pass. *</label>
<input type='password' className='signVal' name='signup_confpass' id='signup_confpass' placeholder='Enter confirm password...' />
</div>

<div className='signUpConf'>
<div>
<span>By clicking on the create button, you agree to the <b>Terms and Conditions</b> and confirm that you are over <b>18 years old.</b></span>
</div>
</div>

<div className='signUpConf signUpBtnD'>
<button onClick={signUpNew}>create account</button>
<button onClick={closesignUp}>cancel</button>
</div>

</div>

<div className='verifyOtpDiv' id='verifyOtpDiv'>
<img src='/images/check.png' alt='otp_check' />
<span>your otp has been sent to your mail account</span>
<span>please check your mail and verify this otp</span>
<span>enter 6 digit otp</span>
<div className='OtpDiv'>
<input type='text' className='otpBox' id='otp_1' onChange={(e) => otpChange(e.target)} maxLength='1' />
<input type='text' className='otpBox' id='otp_2' onChange={(e) => otpChange(e.target)} maxLength='1' />
<input type='text' className='otpBox' id='otp_3' onChange={(e) => otpChange(e.target)} maxLength='1' />
<input type='text' className='otpBox' id='otp_4' onChange={(e) => otpChange(e.target)} maxLength='1' />
<input type='text' className='otpBox' id='otp_5' onChange={(e) => otpChange(e.target)} maxLength='1' />
<input type='text' className='otpBox' id='otp_6' onChange={(e) => otpChange(e.target)} maxLength='1' />
</div>
<div className='resendOtpDiv'>
    <span>yet don't receive otp?</span>
    <span onClick={resendOtp}>resend otp</span>
</div>
<div className='otpBtn'>
<button onClick={verifyOtpConf}>verify otp</button>
<button onClick={verifyOtpCancel}>cancel</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default SignUp;
